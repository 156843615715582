import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth.js';

import { RESERVE_TASKS, RESOLVE_TASK } from '@/graphql/annotator';
import { GET_OBJECT_LABELS } from '@/graphql/annotator/object';
import { apolloClient } from '@/apollo';

import { TASKS, TASKS_BY_ID } from './tasks.js';

const state = () => ({
    task:            null,
    tasksByCategory: TASKS,
    tasksByID:       TASKS_BY_ID,
    tasksCompleted:  -1,
    showHelp:        true,
    footerButtons:   {},
    callbacks:       {},
    objectLabelMap:  {}
});

const getters = {
    noTask: (state) => !state.task,
    taskData: (state) => {
        const authStore = useAuthStore();
        return {
            name: authStore.annotator.task.name,
            category: authStore.annotator.task.category,
            ...state.tasksByCategory[authStore.annotator.task.category][authStore.annotator.task.name]
        };
    }
};

const actions = {
    async nextTask() {
        const authStore = useAuthStore();
        if (!authStore.annotator.task)
            return;

        const result = await apolloClient.mutate({
            mutation: RESERVE_TASKS,
            variables: {
                taskId: authStore.annotator.task.id,
                numTasks: 1
            }
        });
        if (result.data.reserveTasks.taskItems == 0)
            this.task = null;
        else
            this.task = result.data.reserveTasks.taskItems[0];
        this.tasksCompleted++;
    },
    async resolveTask(newStatus) {
        await apolloClient.mutate({
            mutation: RESOLVE_TASK,
            variables: {
                taskItemId: this.task.id,
                newStatus: newStatus
            }
        });
    },
    toggleHelp() {
        this.showHelp = !this.showHelp;
    },
    setFooterButtons(buttons) {
        this.footerButtons = buttons;
    },
    setCallbacks(callbacks) {
        this.callbacks = callbacks;
    },
    callback(action) {
        if (this.callbacks[action] !== undefined)
            this.callbacks[action]();
    },
    async getObjectLabelMap() {
        const result = await apolloClient.query({
            query: GET_OBJECT_LABELS
        });
        result.data.objectLabels.nodes.forEach(objectLabel => this.objectLabelMap[objectLabel.path] = objectLabel.id);
    }
};

export const useAnnotatorStore = defineStore('annotator', {
    state: state,
    getters: getters,
    actions: actions,
    persist: {
        storage: window.sessionStorage
    }
});