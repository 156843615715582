<template>
    <nav class="navbar is-light">
        <div class="navbar-brand">
            <router-link to="/" class="mt-1 ml-1">
                <img :src="require(`@/assets/rocLogo.jpg`)" width="150" />
            </router-link>
        </div>
        <div v-if="isAuthenticated" class="navbar-menu">
            <div v-if="route === 'Annotator' && !noTask" class="navbar-start">
                <h1 v-if="taskData.header" class="navbar-item has-text-black is-unselectable">{{ taskData.header }}</h1>
                <a  v-if="taskData.workflow" class="navbar-item is-flex has-text-black material-icons rounded" id="info" @click="toggleHelp">info</a>
            </div>
            <div v-else-if="route.includes('Browser')" class="navbar-start">
                <router-link to="/browser/persons" class="navbar-item">Persons</router-link>
                <router-link to="/browser/faces" class="navbar-item">Faces</router-link>
                <router-link to="/browser/objects" class="navbar-item">Objects</router-link>
                <router-link to="/browser/fingerprints" class="navbar-item">Fingerprints</router-link>
                <router-link to="/browser/irises" class="navbar-item">Irises</router-link>
                <router-link to="/browser/tattoos" class="navbar-item">Tattoos</router-link>
                <router-link to="/browser/images" class="navbar-item">Images</router-link>
            </div>
            <div class="navbar-end">
                <router-link to="/annotator" class="navbar-item">Annotator</router-link>
                <router-link v-if="isAdmin" to="/browser" class="navbar-item">Browser</router-link>
                <router-link v-if="isAdmin" to="/admin" class="navbar-item">Admin</router-link>
                <logout />
            </div>
        </div>
    </nav>
</template>

<script>
import logout from "@/components/auth/logout.vue";

import { mapState } from 'pinia';
import { useAnnotatorStore } from '@/stores/annotator';
import { useAuthStore } from '@/stores/auth';

export default {
    name: "Navbar",
    components: {
        logout
    },
    computed: {
        ...mapState(useAuthStore, ["isAuthenticated", "isAdmin"]),
        ...mapState(useAnnotatorStore, ["noTask", "taskData"]),
        route() { return this.$route.name || ''; }
    },
    methods: {
        toggleHelp() {
            const annotatorStore = useAnnotatorStore();
            annotatorStore.toggleHelp();
        },
    }
};
</script>